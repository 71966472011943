@font-face {
  font-family:'MessinaSans';
  src: url('../fonts/MessinaSansWeb-Regular.woff2') format('woff2'),
        url('../fonts/MessinaSansWeb-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family:'MessinaSans';
  src: url('../fonts/MessinaSansWeb-Bold.woff2') format('woff2'),
        url('../fonts/MessinaSansWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  margin: 0;
}

body {
  margin: 0;
  color: #000;
  font-family: 'MessinaSans';
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;  /* emphasise on legibility when rendering, turns on ligatures and kerning */    
  -webkit-font-smoothing: antialiased; /* apply font anti-aliasing for Webkit on OSX */    
  -moz-osx-font-smoothing: grayscale;  /* apply font anti-aliasing for Firefox on OSX */
}

a {
  color: #000;
  text-decoration: none;
  border-bottom: 2px solid #000;
}

div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

img {
  width: 240px;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 32px;
}